import React from "react"
import styled from "styled-components"

import Layout from "components/Layout"
import Text from "components/Text"
import Section from "components/Section"

const TextWithImageSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
  align-items: center;
  margin: 0 -50px;
  padding: 0 0px;
  flex-wrap: wrap;
`

const ImageContainer = styled.div`
  flex: 0 1 auto;
  padding: 50px;
`

const TextContainer = styled.div`
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  padding: 50px;
`

const PhoneSectionImage = styled.img`
  display: block;
  width: 100%;
  max-width: 400px;
  height: auto;
  margin: auto;
`
const CardsSectionContainer = styled.div`
  background-image: url(${require("assets/images/home/arkeo-man-section2.png")});
  background-size: 400px;
  background-position: 0% 30%;
  background-repeat: no-repeat;
  min-height: 500px;
  max-width: 960px;
  margin-top: -500px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 960px) {
    background-image: url("");
    padding-bottom: 50px;
  }
`

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 60%;

  @media (max-width: 960px) {
    width: 100%;
  }

  @media (max-width: 480px) {
    margin-top: 50px;
  }
`

const StyledCardImage = styled.div`
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  height: 280px;
`
const StyledCardText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110px;
  padding: 10px 20px;
`

const StyledCard = styled.div`
  flex: 0 0 auto;
  width: 48%;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background: #d7d7d7;
  box-shadow: 0px 5px 20px 5px #0000004a;
  margin-bottom: 20px;

  @media (max-width: 480px) {
    width: 100%;
  }
`

const ScoreAndDataContainer = styled.div`
  margin: 100px 0 100px;
`

const Card = ({ image, text, style }) => (
  <StyledCard style={style}>
    <StyledCardImage src={image} />
    <StyledCardText>{text}</StyledCardText>
  </StyledCard>
)

const WhatIsItSection = () => (
  <Section>
    <Layout.SafeArea>
      <CardsSectionContainer>
        <CardsContainer>
          <Text style={{ width: "100%" }} dark type="header">
            What is it?
          </Text>
          <Card
            image={require("assets/images/home/man.png")}
            text={<Text type="body">We all have mental fitness</Text>}
          />
          <Card
            image={require("assets/images/home/arkeo-woman-yoga-pose.png")}
            text={
              <Text type="body">
                It goes up and down in all of us at different points of our day
              </Text>
            }
          />
          <Card
            image={require("assets/images/home/woman.png")}
            text={
              <Text type="body">
                It's impacted by our lifestyle, life experiences and world
                events
              </Text>
            }
          />
          <Card
            image={require("assets/images/home/man2.png")}
            text={
              <Text type="body">
                It works like your physical fitness preparing you to take on
                challenges
              </Text>
            }
          />
        </CardsContainer>
      </CardsSectionContainer>
      <ScoreAndDataContainer>
        <TextWithImageSection reverse>
          <TextContainer>
            <Text type="header">MY ARKEO SCORE</Text>
            <Text type="body">
              This is your mental fitness score.
              <br />
              Your score is worked out by an algorithm set by existing and
              current data on what's good for the average person.
            </Text>
            <Text type="body">
              Overtime MyArkeo becomes "smart" to what works best with your
              personally.
            </Text>
          </TextContainer>
          <ImageContainer>
            <PhoneSectionImage src={require("assets/images/home/Phone.png")} />
          </ImageContainer>
        </TextWithImageSection>
        <TextWithImageSection>
          <TextContainer>
            <div>
              <Text type="header">YOUR DATA</Text>
              <Text type="body">
                You can collect your data via the app in less than 20 seconds
                each day.
              </Text>
              <Text type="body">
                You can track your daily challenges to see how you cope and what
                areas you might need to improve.
              </Text>
              <Text type="body">
                We protect you and your data by storing it all anonymously.
              </Text>
            </div>
          </TextContainer>
          <ImageContainer>
            <PhoneSectionImage
              src={require("assets/images/home/arkeo-app-phone-mockup-screen2.png")}
            />
          </ImageContainer>
        </TextWithImageSection>
      </ScoreAndDataContainer>
    </Layout.SafeArea>
  </Section>
)

export default WhatIsItSection
