import React from "react"
import styled from "styled-components"

import Layout from "components/Layout"
import Text from "components/Text"
import Section from "components/Section"

const StyledTrackingSection = styled(Section)`
  background-image: url(${require("assets/images/home/arkeo-man-runnning-on-road.png")});
  background-size: cover;
  background-position: center;
  height: 600px;
`

const ContentPosition = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => align};
  height: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    justify-content: center;
  }
`

const TrackingSection = () => (
  <StyledTrackingSection dark>
    <Layout.SafeArea>
      <ContentPosition align="flex-end">
        <div>
          <Text type="header">TRACKING BEHAVIOUR</Text>
          <Text type="body">
            MyArkeo allows you to compare your behaviours and low mental fitness
            challenges to see if there's any connections.
          </Text>
          <Text type="body">
            Simply scroll to the behaviour you want to compare and then select
            the challenge.
            <br />
            MyArkeo will chart the two selected data points.
          </Text>
        </div>
      </ContentPosition>
    </Layout.SafeArea>
  </StyledTrackingSection>
)

export default TrackingSection
