import React from "react"
import styled from 'styled-components'

import Layout from "components/Layout"
import Text from "components/Text"
import Section from "components/Section"


const PartnerImage = styled.img`
max-height: 64px;
min-height: 48px;
margin: 20px;
`

const PartnersSection = () => (
  <Section>
    <Layout.SafeArea>
      <Text.Header>
        PARTNERS AND CLIENTS
      </Text.Header>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <PartnerImage src={require('assets/images/logos/logo1.png')} />
        <PartnerImage src={require('assets/images/logos/logo2.png')} />
        <PartnerImage src={require('assets/images/logos/logo8.png')} />
      </div>
    </Layout.SafeArea>
  </Section>
)

export default PartnersSection