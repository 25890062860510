import React from 'react'
import MainTemplate from 'templates/MainTemplate/MainTemplate'
import Home from "components/Home"

const IndexPage = () => (
  <MainTemplate title="Arkeo - Home page">
    <Home />
  </MainTemplate>
)

export default IndexPage
