import React, { useState } from "react"
import styled from "styled-components"

import Layout from "components/Layout"
import Text from "components/Text"
import Section from "components/Section"
import SendEmailForm from "components/SendEmailForm"

import subscribeUser from "utils/subscribeUser"

const Image = styled.div`
  background-image: linear-gradient(
      rgba(37, 39, 40, 50%) 0%,
      rgba(37, 39, 40, 50%) 50%,
      rgb(37, 39, 40) 90%
    ),
    url(${require("assets/images/home/arkeo-man-clap-hands.png")});
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
  max-width: 960px;
  margin: 0 auto 400px;
  padding: 50px 0 200px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 100px 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 0 0;
  }
`

const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  flex: 1 1;
`

const SignUpContainer = styled.div`
  margin-top: -20px;
`

const TextButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 840px) {
    flex-direction: column;
  }
`

const TitleSection = () => {
  const [email, setEmail] = useState("")

  const handleSubmitEmail = async () => {
    if (await subscribeUser(email)) {
      setEmail("")
    }
  }

  return (
    <Section dark>
      <Layout.SafeArea>
        <Image>
          <Text type="title">
            MENTAL FITNESS,
            <br />
            KNOW YOURSELF
          </Text>
          <ButtonSection>
            <TextButtonWrapper>
              <div>
                <Text type="body">
                  Do you know how your behaviours and everyday choices impact
                  you?
                  <br />
                  MyArkeo is the only mental fitness tracking app that shows you
                  how your habits and routines interact.
                </Text>
                <Text type="body">ARKEO - Know yourself.</Text>
              </div>

              <SignUpContainer>
                <Text type="header">COMING SOON</Text>
                <Text style={{ marginBottom: 15 }} type="body">
                  Sign up to the BETA. Become mentally fit.
                </Text>
                <SendEmailForm
                  value={email}
                  onSubmit={handleSubmitEmail}
                  onChange={setEmail}
                />
              </SignUpContainer>
            </TextButtonWrapper>
          </ButtonSection>
        </Image>
      </Layout.SafeArea>
    </Section>
  )
}
export default TitleSection
