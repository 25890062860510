import React from "react"
import styled from 'styled-components'

import Layout from "components/Layout"

import TitleSection from "components/Home/TitleSection"
import WhatIsItSection from "./Home/WhatIsItSection"
import TrackingSection from "./Home/TrackingSection"
import PartnersSection from "./Home/PartnersSection"


const Home = ({ siteTitle }) => (
  <Layout style={{
    overflowX: 'hidden'
  }}>
    <Layout.Header />
    <Layout.Main>
      <TitleSection />
      <WhatIsItSection />
      <TrackingSection />
      <PartnersSection />
    </Layout.Main>
    <Layout.Footer />
  </Layout>
)

export default Home
